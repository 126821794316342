<template>
    <w-data-table
        :headers="headers"
        :items="items"
        @click:row="item => $router.push({name: 'daybook.form', query: { g: uuid, c: item.uuid }})"
    >
        <template #item.actions>
            <w-icon-next
                color="primary"
            />
        </template>
    </w-data-table>
</template>

<script>
import { getCoursesCollection } from '@apps/school/api/courses'

export default {
    name: 'GroupDayBooks',
    props: {
        uuid: String
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$trans('Course'),
                    value: 'title'
                },
                {
                    value: 'actions',
                    sortable: false,
                    align: 'right'
                }
            ]
        }
    },
    data: () => ({
        items: []
    }),
    mounted() {
        this.fetchCourses()
    },
    methods: {
        async fetchCourses() {
            this.$lprogress.begin()

            try {
                const payload = {
                    group: this.uuid,
                    teacher: this.$auth.member.uuid
                }

                const response = await getCoursesCollection(payload)
                this.items = response.data.data
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
